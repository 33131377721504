import React, { useCallback, useState, useMemo } from 'react';
import { GridContextProvider, swap } from 'react-grid-dnd';
import { useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { logError } from '../../../../../helpers/errors/bug-report';
import { useTranslation } from 'react-i18next';

import S from './basic-info.module.scss';
import { AddressRow } from './address-row';
import { ClubGallery } from './club-gallary';
import { ClubLogo } from './club-logo';
import { Organization } from './organization';
import { shiftImagesLeft, swapImgsLeft } from '../club-services';
import * as ClubFormCtx from '../club-form-context';
import { FormTextField } from '../../../../common/form/form-text-field';
import { InputFormCheckbox } from '../../../../common/form/form-parts/input-form-checkbox';
import { useHasPermission, useBusiness, useUser } from '../../../../../graphql/graph-hooks';
import { BIZ_IMG_ADD } from '../../../../../graphql/mutations/business-image-add';
import { IMG_ORDER } from '../../../../../graphql/mutations/image-order';
import { IMG_DELETE } from '../../../../../graphql/mutations/image-delete';
import { BUSINESS_DETAIL_GQL } from '../../../../../graphql/queries/business-page-query';
import { PERMISSIONS, UI_MODALS } from '../../../../../helpers/enums';
import * as ClubValidations from '../../../../../helpers/validations/club-validation';
import { useModal } from 'components/global-hooks';
import { FormSelectField } from '../../../../common/form/form-select-field';

const indexAddTo = (grid) => (grid === 'logo' ? 0 : 1);

const BasicInfoForm = ({ isDisabled, isNewForm }) => {
  const { t } = useTranslation();
  const { id: businessId, closed } = useBusiness();
  const { admin } = useUser();
  const {
    dispatch,
    state: { form, errors },
  } = ClubFormCtx.useClubFormCtx();
  const { initModal, closeModal } = useModal();

  const setImage = useCallback(
    (index, image_attachment = null, preview = null, isLoading = false, id = null) =>
      dispatch(ClubFormCtx.setClubFormImg(index, { image_attachment, preview, id, isLoading })),
    [dispatch]
  );

  const setIsLoadingImgs = useCallback(
    (imgMap, indexes, isLoading) => {
      let newImgObj = { ...imgMap };
      indexes.forEach((index) => (newImgObj[index].isLoading = isLoading));
      dispatch(ClubFormCtx.setClubFormField('images', newImgObj));
    },
    [dispatch]
  );

  const [order, setOrder] = useState({
    logo: [{ id: 0 }],
    gallery: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }],
  });

  const NUM_IMGS = useMemo(
    () => Object.values(form.images).reduce((acm, { preview }) => (preview ? ++acm : acm), 0),
    [form.images]
  );

  const HAS_PERMISSION = useHasPermission(PERMISSIONS.EDIT_CLUB) && (admin || !closed);

  const [deleteImg, { loading: processDlt }] = useMutation(IMG_DELETE);
  const [addBizImg, { loading: processAdd }] = useMutation(BIZ_IMG_ADD, { variables: { businessId } });
  const [reOrderImgs, { loading: processReOrder }] = useMutation(IMG_ORDER, {
    variables: { businessId, type: 'BUSINESS' },
  });

  const handleImageCrop = (file, index, item, gridId) => {
    const fileType = file.type ? file.type.split('/')[1].toUpperCase() : 'unknown';
    const fileName = file.name;

    const TYPE_LIST = file.type.split('/');

    return new Promise((resolve, reject) => {
      if (HAS_PERMISSION && TYPE_LIST[0] === 'image' && ['png', 'jpg', 'jpeg'].includes(TYPE_LIST[1])) {
        initModal(UI_MODALS.IMAGE_CROP, { isLoading: true });
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = async () => {
          closeModal();
          initModal(UI_MODALS.IMAGE_CROP, {
            imageSrc: reader.result,
            onCropped: handleAddFile.bind(this, file, index, gridId, fileName, fileType),
            aspectRatio: 1,
          });
        };
        resolve();
      } else {
        toast.error(t('errors.unsupported_image_format', { allowed: '.jpeg or .png' }));
        reject('Unsupported Image File');
      }
    });
  };

  const handleAddFile = async (file, index, gridId, fileName, type, data) => {
    const ADD_TO_INDEX = indexAddTo(gridId);
    const SLOT_IS_EMPTY = form.images[index + ADD_TO_INDEX].preview === null;
    const TARGET_INDEX = Object.values(form.images).reduce((acm, { preview }, i) => {
      return acm === null && preview === null ? i : acm;
    }, null);

    setImage(TARGET_INDEX, file, data, !isNewForm);

    if (!isNewForm && SLOT_IS_EMPTY) {
      await addBizImg({
        variables: {
          fileName,
          type,
          data: data.split(',')[1],
        },
        update: (
          cache,
          {
            data: {
              business_add_image: { business },
            },
          }
        ) => {
          cache.writeData({
            id: `Business:${business.id}`,
            data: { images: business.images },
          });
          setImage(TARGET_INDEX, file, data, false, business.images[TARGET_INDEX].id);
        },
      }).catch((err) => {
        setImage(TARGET_INDEX);
        logError(err, 'BIZ_IMG_ADD', BasicInfoForm.displayName);
        toast.error(t('errors.unable_to_add_image'));
      });
    }
  };

  async function onChange(sourceId, sourceIndex, targetIndex, targetId) {
    const SOURCE_INDEX_ADD_TO = indexAddTo(sourceId);
    //everything is a swap but if targetId then it's a move as well
    if (!HAS_PERMISSION || (!targetId && sourceIndex === targetIndex)) {
      return;
    }

    // this would be moving between grids
    if (targetId) {
      const TARGET_INDEX_ADD_TO = indexAddTo(targetId);
      const SOURCE_INDEX = sourceIndex + SOURCE_INDEX_ADD_TO;
      const TARGET_INDEX = targetIndex + TARGET_INDEX_ADD_TO;
      const PLACEHOLDER = form.images[SOURCE_INDEX];

      if (form.images[TARGET_INDEX].preview === null) {
        return;
      }

      let newImgObj = { ...form.images, [SOURCE_INDEX]: form.images[TARGET_INDEX] };
      newImgObj = { ...newImgObj, [TARGET_INDEX]: PLACEHOLDER };
      dispatch(ClubFormCtx.setClubFormField('images', newImgObj));

      //Don't set order cause it will set off a memory leak as of React-grid-dnd

      if (!isNewForm) {
        setIsLoadingImgs(form.images, [SOURCE_INDEX, TARGET_INDEX], true);

        await reOrderImgs({
          variables: { order: Object.values(newImgObj).map(({ id }) => id) },
          update: (
            cache,
            {
              data: {
                image_order: { images },
              },
            }
          ) => {
            cache.writeData({
              id: `Business:${businessId}`,
              data: { images, first_image: images[0] },
            });
            setIsLoadingImgs(newImgObj, [SOURCE_INDEX, TARGET_INDEX], false);
          },
        }).catch((err) => {
          logError(err, 'IMG_ORDER', BasicInfoForm.displayName);
          toast.error(`${t('errors.server.500')}: ${t('errors.unable_to_move_images')}`);
          dispatch(ClubFormCtx.setClubFormField('images', form.images));
          setIsLoadingImgs(form.images, [SOURCE_INDEX, TARGET_INDEX], false);
        });
      }
    } else {
      // swap can only be in gallery
      const SOURCE_INDEX = sourceIndex + SOURCE_INDEX_ADD_TO;
      const TARGET_INDEX = targetIndex + SOURCE_INDEX_ADD_TO;
      const NEW_OBJ = swapImgsLeft(form.images, SOURCE_INDEX, TARGET_INDEX, !isNewForm);
      setOrder({ ...order, gallery: swap(order.gallery, sourceIndex, targetIndex) });
      dispatch(ClubFormCtx.setClubFormField('images', NEW_OBJ));

      if (!isNewForm) {
        await reOrderImgs({
          variables: { order: Object.values(NEW_OBJ).map(({ id }) => id) },
          update: (
            cache,
            {
              data: {
                image_order: { images },
              },
            }
          ) => {
            cache.writeData({
              id: `Business:${businessId}`,
              data: { images },
            });
            setIsLoadingImgs(NEW_OBJ, [1, 2, 3, 4, 5, 6], false);
          },
        }).catch((err) => {
          logError(err, 'IMG_ORDER', BasicInfoForm.displayName);
          toast.error(`${t('errors.server.500')}: ${t('errors.unable_to_move_images')}`);
          dispatch(ClubFormCtx.setClubFormField('images', form.images));
          setIsLoadingImgs(form.images, [SOURCE_INDEX, TARGET_INDEX], false);
        });
      }
    }
  }

  async function handleDeleteFile(index, gridId) {
    if (!HAS_PERMISSION) {
      return;
    }
    const IMG_INDEX = index + indexAddTo(gridId);

    if (isNewForm) {
      setImage(IMG_INDEX);
    } else {
      setIsLoadingImgs(form.images, [IMG_INDEX], true);
      const IMG_ID = form.images[IMG_INDEX].id;
      await deleteImg({
        variables: { imgId: IMG_ID },
        update: (cache) => {
          const { business } = cache.readQuery({
            query: BUSINESS_DETAIL_GQL,
            variables: { businessId },
          });

          cache.writeData({
            id: `Business:${businessId}`,
            data: { images: business.images.filter(({ id }) => id !== IMG_ID) },
          });

          dispatch(ClubFormCtx.setClubFormField('images', shiftImagesLeft(form.images, IMG_INDEX)));
        },
      }).catch((err) => {
        logError(err, 'IMG_DELETE', BasicInfoForm.displayName);
        toast.error(t('errors.unable_to_remove_image'));
        setIsLoadingImgs(form.images, [IMG_INDEX], false);
      });
    }
  }

  return (
    <div className={S.basicInfo}>
      <GridContextProvider onChange={onChange}>
        <FormTextField
          id='club_form_name'
          name='name'
          label={t('clubPage.club_name')}
          gridArea='clubName'
          error={errors.name}
          isDisabled={isDisabled}
          value={form.name}
          handleChange={({ target: { name, value } }) => {
            dispatch(ClubFormCtx.setClubFormError(name, ClubValidations.validateClubName(value)));
            dispatch(ClubFormCtx.setClubFormField(name, value));
          }}
          handleBlur={({ target: { name, value } }) => {
            dispatch(ClubFormCtx.setClubFormError(name, ClubValidations.validateClubName(value.trim())));
            dispatch(ClubFormCtx.setClubFormField(name, value.trim()));
          }}
        />

        <Organization isDisabled={isDisabled} error={errors.orgId} />

        <ClubLogo
          canMove={NUM_IMGS > 1}
          order={order.logo}
          hasPermission={HAS_PERMISSION}
          isDisabled={processDlt || processAdd || processReOrder}
          handleDelete={handleDeleteFile}
          handleAdd={handleImageCrop}
        />

        <div className={S.clubOptions}>
          <InputFormCheckbox
            id='club_form_isActive'
            label={t('clubPage.club_is_active')}
            name='isActive'
            isChecked={form.isActive}
            isDisabled={isDisabled}
            handleChange={(e) => dispatch(ClubFormCtx.setClubFormField(e.target.name, !form.isActive))}
          />

          <InputFormCheckbox
            id='club_form_reviews'
            label={t('clubPage.allow_reviews')}
            name='allowReviews'
            isChecked={form.allowReviews}
            isDisabled={isDisabled}
            handleChange={(e) => dispatch(ClubFormCtx.setClubFormField(e.target.name, !form.allowReviews))}
          />

          <InputFormCheckbox
            id='club_form_free_agents'
            label={t('clubPage.allow_free_agents')}
            name='hasFreeAgents'
            isChecked={form.hasFreeAgents}
            isDisabled={isDisabled}
            handleChange={(e) => dispatch(ClubFormCtx.setClubFormField(e.target.name, !form.hasFreeAgents))}
          />

          <FormSelectField
            id='under_21_allowed'
            label={t('clubPage.under_21_allowed')}
            name='under21Allowed'
            classname={S.under21Allowed}
            value={form.under21Allowed == null ? '' : !form.under21Allowed ? 'false' : 'true' }
            isDisabled={isDisabled}
            options={[
              { label: t('clubPage.state_default'), value: '' },
              { label: t('clubPage.no'), value: 'false' },
              { label: t('clubPage.yes'), value: 'true' },
            ]}
            handleChange={({ target: { name, value } }) => {
              const newValue = !value ? null : value === 'true';

              dispatch(ClubFormCtx.setClubFormField(name, newValue));
            }}
          />
        </div>

        <ClubGallery
          order={order.gallery}
          hasPermission={HAS_PERMISSION}
          isDisabled={processDlt || processAdd || processReOrder}
          handleDelete={handleDeleteFile}
          handleAdd={handleImageCrop}
        />
      </GridContextProvider>

      <AddressRow isDisabled={isDisabled} />

      <FormTextField
        id='club_form_phone'
        gridArea='phone'
        name='phone'
        label={t('common.phone')}
        error={errors.phone}
        isDisabled={isDisabled}
        value={form.phone}
        handleChange={({ target: { name, value } }) => {
          dispatch(ClubFormCtx.setClubFormError(name, ClubValidations.validateClubPhone(value)));
          dispatch(ClubFormCtx.setClubFormField(name, value));
        }}
        handleBlur={({ target: { name, value } }) => {
          dispatch(ClubFormCtx.setClubFormError(name, ClubValidations.validateClubPhone(value.trim())));
          dispatch(ClubFormCtx.setClubFormField(name, value.trim()));
        }}
      />

      <FormTextField
        id='club_form_url'
        gridArea='url'
        name='url'
        label={t('common.website')}
        error={errors.url}
        isDisabled={isDisabled}
        value={form.url}
        handleChange={({ target: { name, value } }) => {
          dispatch(ClubFormCtx.setClubFormError(name, ClubValidations.validateClubUrl(value)));
          dispatch(ClubFormCtx.setClubFormField(name, value));
        }}
        handleBlur={({ target: { name, value } }) => {
          dispatch(ClubFormCtx.setClubFormError(name, ClubValidations.validateClubUrl(value.trim())));
          dispatch(ClubFormCtx.setClubFormField(name, value.trim()));
        }}
      />
    </div>
  );
};

BasicInfoForm.displayName = 'BasicInfoForm';

BasicInfoForm.propTypes = {
  isNewForm: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

BasicInfoForm.defaultProps = {
  isNewForm: false,
};

export { BasicInfoForm };
